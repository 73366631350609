import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Layout from '../../components/layout';
import Card from '../../components/card';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};

const MDXLayout = ({
  children
}) => <Layout wide>
    <Card>{children}</Card>
  </Layout>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Socket Studio`}</h1>
    <p>{`Socket Studio is a service designed to make adding sound effects to your Twitch™ streams feel like deploying serverless functions.`}</p>
    <blockquote>
      <p parentName="blockquote">{`⚠️ This product is still in alpha. If you’d like to be added as an alpha tester, please contact `}<a parentName="p" {...{
          "href": "mailto:jason@lengstorf.com"
        }}>{`jason@lengstorf.com`}</a>{`.`}</p>
    </blockquote>
    <h2>{`How it works`}</h2>
    <ol>
      <li parentName="ol">{`Join Socket Studio`}</li>
      <li parentName="ol">{`Create a serverless handler`}</li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://netlify.com/?utm_source=twitch-blitz-jl&utm_medium=github&utm_campaign=devex"
        }}>{`Deploy to Netlify`}</a></li>
      <li parentName="ol">{`Run your sound effect!`}</li>
    </ol>
    <h3>{`Creating a Socket Studio serverless handler`}</h3>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Install the serverless handler util`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-bash"
          }}>{`# install the handler util
yarn add @stream-blitz/create-handler
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Create a serverless function:`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-js"
          }}>{`const createHandler = require('@stream-blitz/create-handler');

exports.handler = createHandler(() => ({
  name: 'boop', // command to trigger this effect in chat, e.g. \`!boop\`
  message: 'you triggered !boop Kappa', // sent to the chat by Socket Studio
  description: 'Information about the command', // shown in Socket Studio
  audio: 'https://example.org/path/to/sound.mp3', // played in your overlay
  image: 'https://example.org/image.gif', // shown in the overlay
  duration: 10, // how long (in seconds) to show the overlay
}));
`}</code></pre>
        <p parentName="li">{`Note: only `}<inlineCode parentName="p">{`name`}</inlineCode>{` is required — the rest of the fields are optional and will be omitted if left out (e.g. you can send a chat message without an overlay and vice versa)`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Register your serverless function`}</p>
        <p parentName="li">{`Visit your `}<a parentName="p" {...{
            "href": "/"
          }}>{`Socket Studio dashboard`}</a>{` to pull in your effects!`}</p>
      </li>
    </ol>
    <h2>{`API`}</h2>
    <p>{`The API for `}<inlineCode parentName="p">{`@stream-blitz/create-handler`}</inlineCode>{` tries to keep things as simple as possible:`}</p>
    <h3>{`Arguments`}</h3>
    <p>{`The handler function receives`}</p>
    <h2>{`TODO`}</h2>
    <ul {...{
      "className": "contains-task-list"
    }}>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`register chat handlers`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`register custom overlay styles`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`register custom overlay scripts`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`add support for multi-channel commands (e.g. teams opting into SFX)`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      